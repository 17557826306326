<template>
  <div class="home fixed-sn">
    <owgheader></owgheader>
    <div class="flexible-content" >
      <topnavbar />
      <main>
        <div>
          <mdb-container fluid>
            <h1>{{$t('users.users_list')}}</h1>
            <mdb-alert color="danger" v-if="error">{{error}}</mdb-alert>
            <mdb-container fluid>
              <mdb-btn class="float-right" color="primary" size="sm" v-on:click="$router.push({ name: 'UserAdd' })">{{$t('users.user_add')}}</mdb-btn>
            </mdb-container>
            <mdb-tbl btn striped>
              <mdb-tbl-head>
                <tr>
                  <th>{{$t('users.name')}}</th>
                  <th>{{$t('users.email')}}</th>
                  <th>{{$t('users.accesslevel')}}</th>
                  <th>{{$t('users.lastconnection')}}</th>
                  <th>&nbsp;</th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr v-for="user in userslist" v-bind:key="user.id">
                  <td>
                    <strong>{{user.name}}</strong>
                  </td>
                  <td>{{user.email}}</td>
                  <td>{{user.accesslevel}}</td>
                  <td v-html="lastConnectionFormat(user.accesslogs)"></td>
                  <td class="float-right">
                    <mdb-btn size="sm" outline="elegant" v-on:click="detailsuser(user.id)">{{$t('users.details')}}</mdb-btn>
                    <mdb-btn size="sm" outline="danger" v-on:click="deleteuser(user)" v-if="user.deletable && user.id!==myuserid">{{$t('users.delete')}}</mdb-btn>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>
          </mdb-container>
        </div>
      </main>
    </div>
    <mdb-modal :show="confirm" @close="confirm = false" size="md" class="text-center" danger>
      <mdb-modal-header center :close="false">
        <p class="heading">{{$t('users.are_you_sure')}}</p>
      </mdb-modal-header>
      <mdb-modal-body v-if="currentuser">
        {{$t('users.delete_warning')}} <br /><br /><strong>{{currentuser.name}} ({{currentuser.email}})</strong><br />
        <mdb-icon icon="times" size="4x" class="animated rotateIn"/>
      </mdb-modal-body>
      <mdb-modal-footer center>
        <mdb-btn outline="danger" v-on:click="confirmDeleteUser()">{{$t('users.yes')}}</mdb-btn>
        <mdb-btn color="danger" @click="confirm = false">{{$t('users.no')}}</mdb-btn>
      </mdb-modal-footer>
    </mdb-modal>
  </div>
</template>

<script>
import {
  mdbBtn,
  mdbContainer,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbAlert,
  mdbModal,
  mdbModalHeader,
  mdbModalBody,
  mdbModalFooter,
  mdbIcon,
} from 'mdbvue';
import owgheader from '@/components/Header.vue';
import topnavbar from '@/components/Topnavbar.vue';
import Apicall from '@/libs/Apicall';

export default {
  name: 'usersList',
  data() {
    return {
      dummy: '',
      error: null,
      myuserid: null,
      userslist: [],
      confirm: false,
      currentuser: null,
    };
  },
  components: {
    owgheader,
    topnavbar,
    mdbBtn,
    mdbContainer,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbAlert,
    mdbModal,
    mdbModalHeader,
    mdbModalBody,
    mdbModalFooter,
    mdbIcon,
  },
  mounted() {
    this.loadUsers();
  },
  methods: {
    loadUsers() {
      const self = this;
      const api = new Apicall();
      api.call('GET', 'api/user/list').then((response) => {
        if (response.status === 'OK') {
          self.userslist = response.data.users;
          self.myuserid = response.data.currentuser;
        } else {
          self.error = response.msg;
        }
      });
    },
    lastConnectionFormat(elm) {
      if (elm.length) {
        const ladate = `${this.getDate(elm[0].created_at)} ${this.getTime(elm[0].created_at)}<br />${elm[0].city}, ${elm[0].region}, ${elm[0].country}`;
        return ladate;
      }
      return 'Never';
    },
    getDate(timestamp) {
      const thedate = new Date(timestamp);
      thedate.setHours(0, 0, 0, 0);
      const options = {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      };
      return thedate.toLocaleDateString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    getTime(timestamp) {
      const thedate = new Date(timestamp);
      const options = {
        hour: '2-digit',
        minute: '2-digit',
      };
      return thedate.toLocaleTimeString(this.$store.state.currentlang === 'fr' ? 'fr-CA' : 'en-CA', options);
    },
    detailsuser(_id) {
      this.$router.push({ name: 'UserDetails', params: { id: _id } });
    },
    deleteuser(user) {
      this.currentuser = user;
      this.confirm = true;
    },
    confirmDeleteUser() {
      this.confirm = false;
      const self = this;
      const api = new Apicall();
      api.call('POST', 'api/user/delete', { id: this.currentuser.id }).then((response) => {
        if (response.status === 'ERROR') {
          self.error = response.msg;
        }
        this.currentuser = null;
        this.userslist = [];
        this.loadUsers();
      });
    },
  },
};
</script>
